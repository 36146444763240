<template>
  <section class="checkout-container">
    <img width="200" :src="orderDoneIcon" alt="order done successfully" />
    <h1>{{ $t("your_order_done_successfully") }}</h1>
    <b-button class="primar-button">
      <b-link to="/explore">
        {{ $t("explore_more_courses") }}
      </b-link>
    </b-button>
  </section>
</template>

<script>
import { BButton, BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BButton,
  },
  data() {
    return {
      orderDoneIcon: require("/src/assets/images/icons/order-done.svg"),
    };
  },
};
</script>

<style lang="scss">
.checkout-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .primar-button {
    display: inline-flex;
    height: 36px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #135991 !important;
    display: inline;
    font-family: Poppins;
    border: none;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    a {
      color: #fff !important;
    }
  }
  @media screen and (max-width: 700px) {
    img {
      width: 100px;
    }
    h1 {
      font-size: 22px;
    }
  }
}
</style>
